import { GRID_DATA_TYPE } from 'src/app/core/constants/common';

export const ExistingForm = {
  formCode: {
    header: 'ID',
    sort: true,
    type: GRID_DATA_TYPE.TEXT,
    width: '200',
    filter: false,
    icon: false,
  },
  formName: {
    header: 'Name',
    sort: true,
    type: GRID_DATA_TYPE.TEXT,
    width: '300',
    filter: false,
    icon: false,
  },
  formDescription: {
    header: 'Description',
    sort: false,
    width: '300',
    filter: false,
    type: GRID_DATA_TYPE.TEXT,
    icon: false,
  },
  // formPreview: {
  //   header: 'Preview',
  //   sort: false,
  //   type: GRID_DATA_TYPE.TEXT,
  //   width: '200',
  //   filter: false,
  //   icon: false,
  // },
  organization: {
    header: 'Organization',
    sort: false,
    type: GRID_DATA_TYPE.TEXT,
    width: '200',
    filter: false,
    icon: true,
  },
  vertical: {
    header: 'Vertical',
    sort: false,
    type: GRID_DATA_TYPE.TEXT,
    width: '200',
    filter: false,
    icon: true,
  },
  division: {
    header: 'Division',
    sort: false,
    type: GRID_DATA_TYPE.TEXT,
    width: '200',
    filter: true,
    icon: true,
  },
  department: {
    header: 'Department',
    sort: false,
    type: GRID_DATA_TYPE.TEXT,
    width: '200',
    filter: true,
    icon: true,
  },
  createdDate: {
    header: 'Created Date',
    sort: true,
    type: GRID_DATA_TYPE.TEXT,
    width: '200',
    filter: false,
    icon: true,
  },
  createdBy: {
    header: 'Created By',
    sort: true,
    type: GRID_DATA_TYPE.TEXT,
    width: '240',
    filter: false,
    icon: true,
  },
  formStatus: {
    header: 'Status',
    sort: false,
    type: GRID_DATA_TYPE.TEXT,
    width: '200',
    filter: true,
    icon: true,
  },
  formAction: {
    header: 'Action',
    sort: false,
    type: GRID_DATA_TYPE.TEXT,
    width: '200',
    filter: false,
    icon: true,
  },
};

export const LISTING_TYPE = {
  DRAFT: ['DRAFT'],
  ALL: ['PUBLISHED', 'DECOMMISIONED', 'INPROGRESS'],
};

export const FORM_STATUS = {
  DRAFT: 'Draft',
  PUBLISHED: 'Published',
  DECOMMISIONED: 'De-Commissioned',
  ALL: 'ALL',
  INPROGRESS: 'In-Progress',
};

export const FORM_STEPS = [
  {
    label: 'Basic Details',
  },
  {
    label: 'Questions',
  },
  {
    label: 'URL Variables',
  },
  {
    label: 'Publish',
  },
];

export const EMAIL_STEPS = [
  {
    label: 'Basic Details',
  },
  {
    label: 'Select Layout',
  },
  {
    label: 'Configuration',
  },
];
