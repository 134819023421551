<div class="p-inputgroup">
  <input
    type="text"
    pInputText
    [disabled]="true"
    [value]="file?.name || ''"
    pTooltip="{{ file?.name?.length > 48 ? file?.name : '' }}"
  />
  <button
    type="button"
    pButton
    label="Choose File"
    styleClass="p-button-warn"
    (click)="onSelectFile()"
  ></button>
  <input
    type="file"
    id="file-picker"
    hidden
    (change)="uploadFile($event)"
    [accept]="this.allowedMimeTypes"
  />
</div>
