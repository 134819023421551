import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonService } from '../../services/common.service';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { SegmentButtonType } from 'src/app/core/constants/common';
import { IFileDetails } from 'src/app/modules/segments/interfaces/create-segment.interface';

@Component({
  selector: 'app-file-info-popup',
  templateUrl: './file-info-popup.component.html',
  styleUrls: ['./file-info-popup.component.scss'],
})
export class FileInfoPopupComponent implements OnInit {
  showFilePicker = false;
  showCancelButton = false;
  title = 'Generate Filename and Path';
  cancelButtonText: string;
  allowedExtensions = ['csv'];
  fileMaxSize = 1;
  allowedMimeTypes = 'text/csv';
  enableMapPlaceholder = true;
  selectedButtonType: string;
  fileHeaders: string[] = [];
  fileDetails: IFileDetails;

  constructor(
    private readonly config: DynamicDialogConfig,
    private readonly dialogRef: DynamicDialogRef,
    private readonly commonService: CommonService,
    private readonly ngxCsvParser: NgxCsvParser
  ) {
    this.selectedButtonType = this.config?.data?.type;
    this.fileDetails = this.config?.data?.fileDetails;
    console.log('check detials', this.fileDetails);
  }

  ngOnInit(): void {
    if (this.selectedButtonType === SegmentButtonType.GENERATE) {
      this.setGenerateConfig();
    } else if (this.selectedButtonType === SegmentButtonType.UPLOAD) {
      this.setUploadFileConfig();
    }
  }

  onCancelClick(toBeClosed?: boolean): void {
    if (toBeClosed || this.selectedButtonType === SegmentButtonType.UPLOAD) {
      this.dialogRef.close();
    } else {
      this.setGenerateConfig();
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close({
      data: this.fileHeaders,
    });
  }

  setGenerateConfig(): void {
    this.showFilePicker = false;
    this.showCancelButton = false;
    this.title = 'Generate Filename and Path';
    this.enableMapPlaceholder = true;
  }

  setUploadFileConfig(): void {
    this.showFilePicker = true;
    this.title = 'Upload Sample File';
    this.showCancelButton = true;
    this.cancelButtonText =
      this.selectedButtonType === SegmentButtonType.UPLOAD
        ? 'Cancel'
        : 'Go Back';
    this.enableMapPlaceholder = false;
  }

  onSelectedFiles(file: File): void {
    this.ngxCsvParser
      .parse(file, {
        header: false,
        delimiter: ',',
        encoding: 'utf8',
      })
      .pipe()
      .subscribe({
        next: (result: any): void => {
          if (result?.length) {
            const fileHeaders = result[0];
            if (fileHeaders?.length) {
              this.fileHeaders = fileHeaders;
              this.enableMapPlaceholder = true;
            }
          }
        },
        error: (error: NgxCSVParserError): void => {
          console.log('Error', error);
        },
      });
  }

  copyLink(type: string): void {
    const selectedLabel = type === 'FILENAME' ? 'Filename' : 'Path';
    const selectedText =
      type === 'FILENAME'
        ? this.fileDetails?.fileName
        : this.fileDetails?.filePath;

    navigator.clipboard.writeText(selectedText);
    this.commonService.showSuccessMessage(`${selectedLabel} copied!`);
  }
}
