import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.scss'],
})
export class FilePickerComponent {
  @Input() allowedExtensions: string[];
  @Input() fileMaxSize: number;
  @Input() allowedMimeTypes: string;
  @Output() selectedFiles: EventEmitter<File> = new EventEmitter<File>();

  file: any;

  constructor(private readonly commonService: CommonService) {}

  onSelectFile(): void {
    const fileInput = document.getElementById(
      'file-picker'
    ) as HTMLInputElement;
    fileInput?.click();
  }

  uploadFile(event: any): void {
    const allowedExtensions = this.allowedExtensions;
    const files: FileList = event.target.files;
    const fileSize = files[0].size / 1024 / 1024;
    const fileExtension = files[0].name.split('.').pop()?.toLowerCase();

    if (fileSize > this.fileMaxSize) {
      this.commonService.showErrorMessage(
        `File size exceeds ${this.fileMaxSize}MB limit.`
      );
      this.resetSelectedFile();
      return;
    }

    if (!allowedExtensions.includes(fileExtension || '')) {
      this.commonService.showErrorMessage(
        `Invalid file type. Please upload a ${this.allowedExtensions.join()} file.`
      );
      this.resetSelectedFile();
      return;
    }

    this.file = files[0];
    this.selectedFiles.emit(this.file);
  }

  resetSelectedFile(): void {
    const fileInput = document.getElementById(
      'file-picker'
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }
}
