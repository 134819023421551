<ng-container *ngIf="type === typeMap.PAUSE">
  <div class="row">
    <div class="col-md-6">
      <label for="pauseOn" class="form-label"> Pause On </label>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-calendar"></i>
        </span>
        <p-calendar
          dateFormat="dd/mm/yy"
          [readonlyInput]="true"
          [disabled]="true"
          placeholder="Select Date"
          [(ngModel)]="popupData.pauseDate"
          appendTo="body"
        ></p-calendar>
      </div>
    </div>
    <div class="col-md-6">
      <label for="resumeOn" class="form-label"> Resume from </label>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-calendar"></i>
        </span>
        <p-calendar
          #calendarInput
          dateFormat="dd/mm/yy"
          placeholder="DD/MM/YYYY"
          [disabled]="resumeLater"
          [minDate]="popupData.minDate"
          [maxDate]="popupData.maxDate"
          [(ngModel)]="popupData.resumeDate"
          appendTo="body"
        ></p-calendar>
      </div>
      <div class="d-flex align-items-center mt-2 gap-2">
        <small class="text-muted font-weight-bold">Resume later</small>
        <div class="form-check form-switch">
          <input
            class="form-check-input cursor-pointer form-check-inline"
            type="checkbox"
            role="switch"
            aria-checked="true"
            id="flexButtonSwitch"
            [(ngModel)]="resumeLater"
            (click)="toggleSwitch()"
            (keydown)="$event.preventDefault()"
            [disabled]="disableSwitch"
          />
        </div>
      </div>
    </div>
  </div>

  <p class="mt-3">Are you sure you want to pause this Campaign?</p>
</ng-container>

<ng-container *ngIf="type === typeMap.RESUME">
  <div class="row">
    <div class="col-md-12">
      <label for="resumeOn" class="form-label"> Resume from </label>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-calendar"></i>
        </span>
        <p-calendar
          dateFormat="dd/mm/yy"
          [placeholder]="'DD/MM/YYYY'"
          [minDate]="popupData.minDate"
          [maxDate]="popupData.maxDate"
          placeholder="Select Date"
          [(ngModel)]="popupData.resumeDate"
          appendTo="body"
        ></p-calendar>
      </div>
    </div>
  </div>
  <p
    class="mt-3 d-flex align-items-center gap-2 text-primary"
    *ngIf="popupData?.showInfoText"
  >
    <i class="pi pi-info-circle"></i>
    <small>
      The campaign will resume from tomorrow onwards as the execution start time
      has already passed.</small
    >
  </p>
  <p class="mt-3">Are you sure you want to resume this Campaign?</p>
</ng-container>

<ng-container *ngIf="type === typeMap.RESCHEDULE">
  <div class="row">
    <div class="col-md-6">
      <label for="executionDate" class="form-label">
        Previous Execution Date
      </label>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-calendar"></i>
        </span>
        <p-calendar
          dateFormat="dd/mm/yy"
          [readonlyInput]="true"
          [disabled]="true"
          [(ngModel)]="popupData.executionDate"
          appendTo="body"
        ></p-calendar>
      </div>
    </div>
    <div class="col-md-6">
      <label for="startTime" class="form-label"> Start Time </label>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-clock"></i>
        </span>
        <p-calendar
          [timeOnly]="true"
          [disabled]="true"
          [(ngModel)]="popupData.startTime"
          appendTo="body"
        ></p-calendar>
      </div>
    </div>
  </div>
  <div
    class="mt-3 text-primary"
    *ngIf="
      popupData.segmentType === SEGMENT_TYPE.AUTOMATED &&
      popupData?.rowData?.frequency
    "
  >
    <img class="me-2" src="assets/svg/common/recur.svg" />
    <b>Recurrence Frequency: </b>
    {{ popupData?.rowData?.cronExpDesc }}
  </div>
  <p class="mt-3">Are you sure you want to reschedule this Campaign?</p>
</ng-container>

<div class="modal-footer mt-4">
  <button type="button" class="btn btn-footer me-2" (click)="onCancelClick()">
    Cancel
  </button>
  <button type="button" class="btn btn-footer" (click)="onConfirmClick()">
    Confirm
  </button>
</div>
