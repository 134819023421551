<div class="common-grid">
  <p-table
    #commonTable
    [value]="data"
    (onSort)="onSort($event)"
    styleClass="p-datatable-striped"
    [resetPageOnSort]="false"
    [paginator]="data.length > 0 ? true : false"
    (onPage)="onPagination($event)"
    [totalRecords]="totalRecords"
    [tableStyle]="{ 'min-height': 'auto', 'min-width': '90rem' }"
    [rows]="pageSize"
    [lazy]="true"
    (onFilter)="applyFilter($event)"
    [rowsPerPageOptions]="gridConfig.pageSizeOptions"
    [resizableColumns]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th
          *ngFor="let col of headers"
          class="table-header"
          scope="col"
          [id]="col.key"
          [pSortableColumn]="col.key"
          [pSortableColumnDisabled]="col.sort ? false : true"
          [style.width.px]="col.width"
          pResizableColumn
        >
          {{ col.header }}

          <p-sortIcon *ngIf="col.sort" [field]="col.key"></p-sortIcon>

          <ng-container *ngIf="col.filter">
            <p-columnFilter
              display="menu"
              type="text"
              [showAddButton]="false"
              [showMatchModes]="true"
              [showOperator]="false"
              [field]="col.key"
              [matchModeOptions]="matchModeOptions"
            >
            </p-columnFilter>
          </ng-container>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr class="table-body">
        <td *ngFor="let col of headers" [ngSwitch]="col.type">
          <ng-container *ngSwitchCase="GRID_DATA_TYPE.BUTTONS">
            <ng-container *ngFor="let button of col.buttons">
              <span
                [ngSwitch]="button.buttonType"
                (click)="onButtonClick(rowData, button.eventName)"
                *ngIf="!button.isView || rowData[button.isView]"
                class="{{ button.classes }}"
                [ngClass]=""
                pTooltip="{{ button.tooltipText ? button.tooltipText : '' }}"
                [escape]="false"
                tooltipPosition="top"
                (keydown)="$event.preventDefault()"
                class="common-grid-btn"
              >
                <ng-container *ngSwitchCase="BUTTON_TYPE.ICONBTN">
                  <img
                    alt="icon-btn"
                    src="assets/svg/{{ button.icon }}"
                    href="#"
                  />
                </ng-container>

                <ng-container *ngSwitchCase="BUTTON_TYPE.TEXTICONBTN">
                  <button>
                    <img
                      alt="text-icon-btn"
                      src="assets/svg/{{ button.icon }}"
                      href="#"
                    />
                    {{ button.name }}
                  </button>
                </ng-container>

                <ng-container *ngSwitchCase="BUTTON_TYPE.LINKBTN">
                  <a class="link">
                    {{ button.name }}
                  </a>
                </ng-container>

                <ng-container *ngSwitchCase="BUTTON_TYPE.TEXTBTN">
                  <button>
                    {{ button.name }}
                  </button>
                </ng-container>

                <ng-container *ngSwitchCase="BUTTON_TYPE.ICONTEXTLINK">
                  <a class="link">
                    <img
                      *ngIf="button.icon"
                      src="assets/svg/{{ button.icon }}"
                      href="#"
                      alt=""
                    />
                    <img *ngIf="!button.icon" href="#" alt="" />
                    {{ button.name }}
                  </a>
                </ng-container>

                <ng-container *ngSwitchCase="BUTTON_TYPE.TEXTICONLINK">
                  <a class="link">
                    <span class="right-icon">
                      <span>{{ button.name }}</span>
                      <img
                        *ngIf="button.icon"
                        src="assets/svg/{{ button.icon }}"
                        href="#"
                        alt=""
                      />
                    </span>
                  </a>
                </ng-container>
              </span>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="GRID_DATA_TYPE.LINK">
            <span
              (click)="onCellClick(rowData, col)"
              (keydown)="$event.preventDefault()"
            >
              <a href="#" class="col-link">{{ rowData[col.key] }}</a>
            </span>
          </ng-container>

          <ng-container *ngSwitchCase="GRID_DATA_TYPE.DATE">
            <span *ngIf="rowData[col.key]">{{
              rowData[col.key] | date : col.dateFormatter
            }}</span>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <span
              class="default-span ellipsis"
              [ngClass]="col.classes ? rowData[col.classes] : ''"
              [escape]="false"
              pTooltip="{{
                col.tooltipKey &&
                rowData[col.tooltipKey] &&
                rowData[col.tooltipKey].length > 20
                  ? rowData[col.tooltipKey]
                  : ''
              }}"
              tooltipPosition="top"
            >
              <img
                class="pre-icon"
                *ngIf="col.preIcon"
                src="/assets/svg/{{ rowData[col.preIcon] }}"
                alt="preIcon"
              />
              <i
                *ngIf="col.infoText && rowData[col.infoText]"
                class="pi pi-info-circle"
                [escape]="false"
                pTooltip="{{ rowData[col.infoText] }}"
                tooltipPosition="right"
              ></i>
              <span
                [ngClass]="{
                  'primary-color': col.preIcon || col.postIcon,
                  'bold-class': col.infoText
                }"
              >
                {{ rowData[col.key] || "--" }}
              </span>
              <img
                class="post-icon"
                *ngIf="col.postIcon"
                src="/assets/svg/{{ rowData[col.postIcon] }}"
                alt="postIcon"
              />
            </span>
          </ng-container>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <span class="no-data-found">No records found</span>
    </ng-template>
  </p-table>
</div>
