import { Component, OnDestroy, OnInit } from '@angular/core';
import { loginClient } from 'common-partner-login-sdk/lib/esm';
import { environment } from 'src/environments/environment';
import { StorageService } from './core/cache/storage.service';
import { Constants } from './shared/utilities/constants';
import { Subscription, interval, startWith, switchMap } from 'rxjs';
import { CommonService } from './shared/services/common.service';
import { MIME_TYPE } from './core/constants/mime-types';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  userData: any = null;

  constructor(
    private readonly storageService: StorageService,
    private readonly commonService: CommonService
  ) {}

  ngOnInit() {
    loginClient.init(environment.AD_API_KEY);
    this.storageService.loginLogout.subscribe((value: string) => {
      this.userData = value === 'login' ? this.getToken() : null;
    });
  }

  getToken() {
    const token = localStorage.getItem(Constants.STORAGE_KEYS.ID_TOKEN);
    return token ? JSON.parse(token) : '';
  }
}
