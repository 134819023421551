import { IApiResponse } from 'src/app/shared/interfaces/common-api.interface';

export interface ISegmentListResponse extends IApiResponse {
  data?: ISegmentListData;
}

export interface ISegmentListData {
  page: ISegmentPage;
  filteredResponse: ISegmentFilteredData[];
}

export interface ISegmentPage {
  pageNumber: number;
  totalRecords: number;
  pageSize: number;
  totalPageCount: number;
}

export interface ISegmentFilteredData {
  organization: string;
  vertical: string;
  division: string;
  department: string;
  segmentCode: string;
  segmentName: string;
  campaignCode: string;
  templateCode: string;
  fileUrl: string;
  fileName: string;
  status: string;
  createdBy: string;
  createdDate: string;
  modifiedBy: string;
  modifiedDate: string;
  recordCount: number;
}

export interface SegmentListRequest {
  filterValue: string;
  pageNumber: number;
  pageSize: number;
  filterType: string;
}

export enum SegmentStatus {
  CREATED = 'Created',
  IN_PROGRESS = 'In Progress',
  SCHEDULED = 'Campaign Scheduled',
  EXECUTED = 'Campaign Executed',
  FAILED = 'Failed',
  MAPPED = 'Mapped',
  SCRUBBING = 'Scrubbing',
  TRANSFORMING = 'Transforming',
  EXTRACTING = 'Extracting',
  EXTRACTED = 'Extracted',
  SCRUBBED = 'Scrubbed',
  CAMPAIGN_RESCHEDULED = 'Campaign Re-Scheduled',
  SCRUBBING_FAILED = 'Scrubbing Failed',
  EXTRACTION_FAILED = 'Extraction Failed',
  CANCELLED = 'Cancelled',
  PAUSED = 'Paused',
  INVALID = 'Invalid',
}
