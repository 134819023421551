import { IApiResponse } from 'src/app/shared/interfaces/common-api.interface';

export interface IMasterPlaceholderResponse extends IApiResponse {
  data?: IPlaceholder[];
}

export interface IPlaceholder {
  active: boolean;
  placeholderKey: string;
  placeholderValue: string;
}

export interface IPublishedFormResponse extends IApiResponse {
  data?: IPublishedForm[];
}

export interface IPublishedForm {
  formCode: string;
  formName: string;
}

export interface IUploadedFile {
  name: string;
  fileIndex: number;
}

export interface IUploadEmailResponse extends IApiResponse {
  data?: IUploadedFileURL;
}

export interface IWhatsAppMediaResponse extends IApiResponse {
  data?: IUploadedFileURL;
}

export interface IUploadedFileURL {
  url: string[];
}

export enum ChannelType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP',
}

export enum TemplateMode {
  EDIT = 'Edit',
  VIEW = 'View',
  CREATE = 'New',
  COPY = 'Clone',
}

export enum FileType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  DOCUMENT = 'DOCUMENT',
}

export enum MediaDocType {
  STATIC = 'Static',
  DYNAMIC = 'Dynamic',
}

export enum ButtonActionType {
  QUICK_REPLY = 'QUICK_REPLY',
  CALL_BACK = 'CALL_BACK',
  STATIC_CTA = 'STATIC_CTA',
  WEB_FORM_URL = 'WEB_FORM_URL',
  DYNAMIC_CTA = 'DYNAMIC_CTA',
}

export interface ITemplateCreateResponse extends IApiResponse {
  data?: ITemplateSuccessResponse;
}

export interface ITemplateSuccessResponse {
  templateCode: string;
  message: string;
}

export interface ITemplatePayload {
  code?: string;
  name: string;
  channel: string;
  category?: string;
  language: string;
  status: string;
  organizationDetail: {
    organization: string;
    vertical: string;
    division: string;
    department: string;
  };
  whatsAppTemplate?: IWhatsAppPayload;
  smsTemplate?: ISMSPayload;
}

export interface ISMSPayload {
  dltId: string;
  senderId: string;
  principalId: string;
  content: string;
  formCode?: string;
  urlShorten: boolean;
}

export interface IWhatsAppPayload {
  metaId: string;
  template: [
    {
      type: string;
      sequence: string;
      content: string;
      formCode?: string;
      media?: ITemplateMedia;
      button?: IWhatsAppCTAButton[];
    }
  ];
}

export interface ITemplateMedia {
  type: string;
  url: string;
  fileName: string;
}

export interface IWhatsAppCTAButton {
  subType: string;
  sequence: string;
  label: string;
  url: string;
  action: string;
}

export interface IMediaOption {
  label: string;
  value: string;
}

export enum MediaOptionType {
  LINK = 'link',
  ID = 'id',
}

export type CONTENT_TYPE = 'IMAGE' | 'BUTTON' | 'TEXT';

export interface ImageContent {
  url: string;
  mediaUrl?: string;
  height: number;
  width: number;
}

export interface ButtonContent {
  text: string;
  actionUrl: string;
  style: string;
  size: string;
  alignment: string;
  backgroundColor?: string;
  textColor?: string;
}

export interface TextContent {
  htmlText: string;
}

export interface Content {
  type: CONTENT_TYPE;
  properties: ImageContent | ButtonContent | TextContent;
}
export interface Column {
  screenPercentage: string;
  content: Content;
}

export interface Row {
  columns: Column[];
}

export interface Layout {
  rows: Row[];
  maxRows: number;
}
export interface MasterJson {
  templateBackgroundColor: string;
  attachment?: string;
  layout: Layout;
  mode: 'TEMPLATE' | 'CUSTOM';
}

export interface SelectedPosition {
  rowIndex: number | null;
  columnIndex: number | null;
}

export interface TemplateLayout {
  code: string;
  name: string;
  templateJson: string;
  templateImageUrl: string;
  description: string;
}

export interface ItemplateLayoutResponse extends IApiResponse {
  data?: TemplateLayout[];
}

export interface BodyJson {
  attachments: string[];
  subject: string;
}

export interface ItemplateContentResponse extends IApiResponse {
  data: TemplateContent;
}

export interface TemplateContent {
  subject: string;
  templateCode: string;
  htmlContent: string;
  templateJson: string;
  attachments?: string[];
}
